import React, { Component } from 'react'
import requireAuth from "../Authentication/RequireAuthentication";
import { db } from '../../firebase';
import { connect } from "react-redux";
import { storeUser, storeUserId, storeRecipes, addNotification } from "../../redux/actions/";
import {navigate} from 'gatsby';
function mapDispatchToProps(dispatch) {
	return {
        storeUser: user => dispatch(storeUser(user)),
        storeRecipes: recipes => dispatch(storeRecipes(recipes)),
        storeUserId: id => dispatch(storeUserId(id)),
        addNotification: notification => dispatch(addNotification(notification))
	};
}
const mapStateToProps = state => {
	return { user: state.user, uid: state.uid, recipes: state.recipes};
};
const StarChallenge = ({ user, uid, storeUser, addNotification }) => (
  <ConnectedStar user={user} uid={uid} storeUser={storeUser} addNotification={addNotification}/>
);  
class ConnectedStar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agreed: false,
        }
    }
    handleCheckBox(e){
        this.setState({agreed: e.target.checked})
    }
    handleSubmit(){
        let competitors = []; 
        db.signUpToChallenge(this.props.uid, 'Star of ALSDII™ 2020');
        db.getChallengeCompetitors('star2020').then((users)=>{
            if(users){
                competitors = users
            }
            competitors.push(this.props.uid);
            let removeDuplicates = [...new Set(competitors)];
            db.saveChallengeCompetitors('Star of ALSDII™ 2020', removeDuplicates)
                this.props.addNotification('You have entered into the Alpha Lipid™ SDII™ Stars of 2020 Challenge');
            })
        let user = this.props.user;
        user.challenge = 'Star of ALSDII™ 2020';
        Event("Challenge", "Entered challenge: Star of ALSDII™ 2020", this.props.uid);
        this.props.storeUser(user);
        navigate('/profile')
        
    }
    render() {
        let agreed = this.state.agreed === true;
        return (
            <main>
            <div className="pageGrid py-10 ">
                <h1 className="text-2xl font-bold text-secondary mb-4">
                    AGREEMENT TO TERMS
                </h1>
                <article className="text-sm mx-0">
                <p>These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and New Image™ International (“we,” “us” or “our”), concerning your access to and use of the [website name.com] website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”).</p>
                <p>You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibited from using the Site and you must discontinue use immediately.</p>
                <p>The Site may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions").</p>
                <p>Contributions may be viewable by other users of the Site and through third-party websites. As such, any Contributions you transmit may be treated as non-confidential and non-proprietary. When you create or make available any Contributions, you thereby represent and warrant that:</p>
                <ol className="list-decimal pl-10">
                    <li className="my-1 pl-4">the creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.</li>
                    <li className="my-1 pl-4">you are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Site, and other users of the Site to use your Contributions in any manner contemplated by the Site and these Terms and Conditions.</li>
                    <li className="my-1 pl-4">you have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Site and these Terms and Conditions.</li>
                    <li className="my-1 pl-4">your Contributions are not false, inaccurate, or misleading.</li>
                    <li className="my-1 pl-4">your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</li>
                    <li className="my-1 pl-4">your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).</li>
                    <li className="my-1 pl-4">your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
                    <li className="my-1 pl-4">your Contributions do not advocate the violent overthrow of any government or incite, encourage, or threaten physical harm against another.</li>
                    <li className="my-1 pl-4">your Contributions do not violate any applicable law, regulation, or rule.</li>
                    <li className="my-1 pl-4">your Contributions do not violate the privacy or publicity rights of any third party.</li>
                    <li className="my-1 pl-4">your Contributions do not contain any material that solicits personal information from anyone under the age of 18 or exploits people under the age of 18 in a sexual or violent manner.</li>
                    <li className="my-1 pl-4">your Contributions do not violate any federal or state law concerning child pornography, or otherwise intended to protect the health or well-being of minors;</li>
                    <li className="my-1 pl-4">your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.</li>
                </ol>
                <p>Any use of the Site in violation of the foregoing violates these Terms and Conditions and may result in, among other things, termination or suspension of your rights to use the Site.</p>
                <div className="my-4 block">
                    <div className="rounded-lg bg-gray-200 p-5 flex flex-wrap w-full items-center">
                        <label className="mr-5 sm:m-5 block my-2">
                            <input type="checkbox" onChange={this.handleCheckBox.bind(this)} className="mr-5"/>
                            By Ticking the box you are agreeing to the terms and conditions above
                        </label>
                        {agreed?
                        <button className="w-full sm:w-auto bg-primary text-white rounded-lg py-2 inline-block px-4 ml-auto" onClick={this.handleSubmit.bind(this)}>
                            Submit
                        </button>
                        :
                        <button className="w-full sm:w-auto bg-gray-500 text-white rounded-lg py-2 inline-block px-4 ml-auto cursor-not-allowed" disabled>
                            Submit
                        </button>
                        }
                    </div>
                </div>
                </article>
                
            </div>
           
            </main>
        )
    }
}


const Star = connect(
	mapStateToProps,
	mapDispatchToProps
  )(StarChallenge);
  



export default requireAuth(Star);